require('./bootstrap');
require('./main-site')
require('./scroll-bar');
require('./csrf');

let createTooltip = (elem) => {
    let arrowColor = elem.getAttribute('data-tooltip-color') ?? 'question';
    let icon = elem.getAttribute('data-tooltip-icon') ?? '?';

    if (!!elem.getAttribute('data-bs-title')) {
        new bootstrap.Tooltip(elem, {
            html: true,
            template: `
            <div class="tooltip" role="tooltip">
                <div class="tooltip-arrow ${arrowColor}"></div>
                <div class="tooltip-text-wrapper">
                    <div class="tooltip-icon ${arrowColor}">
                        ${icon}
                    </div>
                    <div class="tooltip-inner">/div>
                </div>
            </div>
            `,
            offset: ({ placement, reference, popper }) => {
                if (placement == 'top' || placement == 'bottom') {
                    let iconWidth = 40;
                    let textWidth = popper.width - iconWidth;

                    let skidding = textWidth / 2;

                    let distance = 10


                    return [skidding, distance]
                }

                if (placement == 'left' || placement == 'right') {
                    return [3, 10]
                }

                return []
            }
        })
    }
};

document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach((elem) => createTooltip(elem));

window.createTooltip = (elem) => createTooltip(elem);
