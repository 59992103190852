$(window).on('load', (e) => {
    const selects = $('select.custom-select')

    if (selects.length == 1) {
        createFakeSelect(selects, selects[0], selects[0].classList.value)

        return;
    }

    if (selects.length > 1) {
        selects.each((index, select) => {
            createFakeSelect($(select), select, select.classList.value)
        })
    }
})

let createFakeSelect = (options, elementToPlace = undefined, classes = 'custom-select') => {
    const optionsArr = $(options).find('option')

    const fakeSelect = $(document.createElement('div'))

    const optionsListWrapper = $(document.createElement('div')).addClass('option-list-wrapper')

    const fakeOptionsList = $(document.createElement('ul')).addClass('option-list')

    let selectedOptionText = '';

    optionsArr.each((i, option) => {
        option = $(option)

        if (option.attr('selected') !== undefined) {
            selectedOptionText = option.text()
        }

        let attributes = option[0].attributes

        let resultAttributes = `class="option" data-option-value="${option.val()}" data-option-is-selected="${option.attr('selected') !== undefined}"`

        Array.from(attributes).forEach((attribute, index) => {
            resultAttributes += ` ${attribute.name}="${attribute.value}"`
        })

        fakeOptionsList.append(
            `<li ${resultAttributes}>${option.text()}</li>`
        )
    })

    if (selectedOptionText == '') {
        selectedOptionText = $(optionsArr[0]).first().text()
    }

    optionsListWrapper.append(fakeOptionsList[0].outerHTML)

    fakeSelect.addClass(classes)
        .append(`<span class="title">${selectedOptionText}</span>`)
        .append(optionsListWrapper[0].outerHTML)

    createEventForSelect(fakeSelect, optionsArr)

    if (elementToPlace != undefined) {
        elementToPlace.after(fakeSelect[0])

        return
    }

    options[0].after(fakeSelect[0])
}

let createEventForSelect = (select, originOptions) => {
    const FAKE_SELECT = $(select)
    const FAKE_SELECT_TITLE = FAKE_SELECT.find('.title')
    const FAKE_OPTIONS = FAKE_SELECT.find('.option')
    const ORIGIN_SELECT = $($(originOptions)[0]).parent()

    FAKE_SELECT.on('click', (e) => {
        toggleOptions(FAKE_SELECT.find('.option-list-wrapper'))
    })

    FAKE_OPTIONS.on('click', (e) => {
        let targetOption = $(e.target)

        if (targetOption[0].hasAttribute('disabled') || targetOption.attr('disabled') == 'true') {
            return;
        }

        FAKE_SELECT_TITLE.text(targetOption.text())

        // change selected option in fake select
        FAKE_SELECT.find('[data-option-is-selected="true"]').attr('data-option-is-selected', 'false')
        FAKE_SELECT.find(`[data-option-value="${targetOption.data('option-value')}"]`).attr('data-option-is-selected', 'true')

        // change selected option in origin select
        ORIGIN_SELECT.find('[selected]').removeAttr('selected')
        ORIGIN_SELECT.find(`[value="${targetOption.data('option-value')}"]`).attr('selected', '')
    })
}

let toggleOptions = (options) => {
    $(options).toggle('show')
}
