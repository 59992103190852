import { Tooltip, Modal, Tab, Collapse } from 'bootstrap';

window.bootstrap = {
    Tooltip: Tooltip,
    Modal: Modal,
    Tab: Tab,
    Collapse: Collapse,
};

require('./variables');
