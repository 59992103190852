import Lang from './modules/Lang';

require('./modules/Rating');

const url = window.location.href.split('/');
const baseURL = url[0] + '//' + url[2];           //for example: http://hyperhost.com.ua
const baseURLLang = baseURL + '/' + url[3];       //for example: http://hyperhost.com.ua/ru

const lang = new Lang(baseURL); //create Lang obj

/**
 * This function find in cookies needle cookie by it name
 * Or if didn`t find return false
 *
 * @param {string} name
 */
window.getCookie = (name = '') => {
    if (name == '') {
        return document.cookie;
    }

    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift()
    };

    return null;
}

/**
 * This function needs to set cookie
 *
 * @example setCookie('key', 'val', { expires: 3, path: '/' })
 *
 * @param {string} key
 * @param {*} value
 * @param {object} options object with params `expires` (in days) and `path`
 */
window.setCookie = (key, value, options) => {
    if (typeof options.expires == 'undefined' || typeof options.path == 'undefined') {
        return false
    }

    const d = new Date();
    d.setTime(d.getTime() + (options.expires * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();

    document.cookie = key + "=" + value + ";" + expires + ";path=" + options.path;

    return true
}

let isoCode = !!getCookie('iso_code') ? 'ua' : getCookie('iso_code');



window.pageURL = url
window.pageBaseURL = baseURL
window.pageBaseURLLang = baseURLLang
window.Lang = lang
window.isoCode = isoCode
