'use strict'

document.addEventListener('DOMContentLoaded', function () {
    fetch('/tools/csrf-token', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(response => response.json())
        .then(data => {
            const csrfToken = data.csrf_token;

            $('meta[name="csrf-token"]').attr('content', csrfToken)
            $('input[name="_token"][type="hidden"]').val(csrfToken)
        })
        .catch(error => console.error('Error fetching CSRF token:', error));
});



