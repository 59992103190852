$(() => {
    $.ajax({
        url: 'https://hyperhost.ua/' + $('html').attr('lang') + '/get-event-promo?without-script=1',
        method: 'GET',

        error: (error) => {
            console.error('Failed to get promo event');
        },

        success: (result) => {
            if (result.result != 'ok' || !('view' in result)) {
                return;
            }

            $('body').append(result.view)
        },

        complete: () => {
            let timerEvent = () => {
                let timer = document.querySelector('.event-promo .event-timer');

                let getTime = (timer, timeName) => {
                    return parseInt(timer.querySelector('[data-event-timer-name="' + timeName + '"]').innerText)
                }

                if (timer != null) {
                    const daysData = {
                        time: getTime(timer, 'd'),
                        timeName: 'd'
                    };
                    const hoursData = {
                        time: getTime(timer, 'h'),
                        timeName: 'h'
                    };
                    const minutesData = {
                        time: getTime(timer, 'i'),
                        timeName: 'i'
                    };
                    const secondsData = {
                        time: getTime(timer, 's'),
                        timeName: 's'
                    };

                    let decrementTime = (days, hours, minutes, seconds) => {
                        // Validate the input values
                        if (days < 0 || hours < 0 || minutes < 0 || seconds < 0) {
                            console.log("Invalid input values.");
                            return;
                        }

                        // Convert everything to seconds
                        let totalSeconds = days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds;

                        // Function to update the time display
                        let updateDisplay = () => {
                            let timeData = {
                                d: String(Math.floor(totalSeconds / (24 * 60 * 60))).padStart(2, '0'),
                                h: String(Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60))).padStart(2, '0'),
                                i: String(Math.floor((totalSeconds % (60 * 60)) / 60)).padStart(2, '0'),
                                s: String(totalSeconds % 60).padStart(2, '0'),
                            }

                            timer.querySelector(`[data-event-timer-name="d"]`).innerText = timeData.d
                            timer.querySelector(`[data-event-timer-name="h"]`).innerText = timeData.h
                            timer.querySelector(`[data-event-timer-name="i"]`).innerText = timeData.i
                            timer.querySelector(`[data-event-timer-name="s"]`).innerText = timeData.s
                        }

                        // Initial display
                        updateDisplay();

                        // Decrement time every second
                        const timerInterval = setInterval(function () {
                            if (totalSeconds > 0) {
                                totalSeconds--;
                                updateDisplay();
                            } else {
                                clearInterval(timerInterval);
                                console.log("Time has expired!");
                            }
                        }, 1000); // Update every second
                    }

                    decrementTime(
                        daysData.time,
                        hoursData.time,
                        minutesData.time,
                        secondsData.time
                    );
                }
            }

            let promoBTNSEvent = () => {
                let eventBlock = document.querySelector('.event-promo-section')
                let promoBlock = eventBlock.querySelector('.event-promo')
                let toggleMinBlock = promoBlock.querySelector('.minimal-icon')
                let toggleMaxBlock = eventBlock.querySelector('.event-promo-minimal-toggle')

                let getCookies = (name = '') => {
                    if (name == '') {
                        return document.cookie;
                    }

                    const value = `; ${document.cookie}`;
                    const parts = value.split(`; ${name}=`);
                    if (parts.length === 2) {
                        return parts.pop().split(';').shift()
                    };

                    return null;
                }

                let setCookies = (key, value, options) => {
                    if (typeof options.expires == 'undefined' || typeof options.path == 'undefined') {
                        return false
                    }

                    const d = new Date();
                    d.setTime(d.getTime() + (options.expires * 24 * 60 * 60 * 1000));
                    let expires = "expires=" + d.toUTCString();

                    document.cookie = key + "=" + value + ";" + expires + ";path=" + options.path;

                    return true
                }

                if (eventBlock != null && promoBlock != null && toggleMinBlock != null && toggleMaxBlock != null) {
                    let toggleMax = () => {
                        promoBlock.classList.remove('minimal')
                        eventBlock.classList.remove('minimal')

                        setCookies('show_promo_event', true, {
                            expires: 999,
                            path: '/',
                        })
                    }

                    let toggleMin = () => {
                        promoBlock.classList.add('minimal')
                        eventBlock.classList.add('minimal')

                        setCookies('show_promo_event', false, {
                            expires: 999,
                            path: '/',
                        })
                    }

                    if (getCookies('show_promo_event') == null) {
                        setCookies('show_promo_event', true, {
                            expires: 999,
                            path: '/',
                        })
                    } else {
                        if (getCookies('show_promo_event') == 'true') {
                            toggleMax()
                        } else {
                            toggleMin()
                        }
                    }

                    toggleMinBlock.addEventListener('click', () => {
                        toggleMin()
                    })

                    toggleMaxBlock.addEventListener('click', () => {
                        if (promoBlock.classList.contains('minimal')) {
                            toggleMax()
                        } else {
                            toggleMin()
                        }
                    })
                }

                let promoCodeBlock = document.querySelector('.event-promo .promo-code-block');

                if (promoCodeBlock == null) {
                    return;
                }

                let copyTooltip = null;

                // Only for hyperhost event promo
                // It no working on hyperhost.ua/info, so dont copy it to it
                try {
                    createTooltip(promoCodeBlock)

                    copyTooltip = bootstrap.Tooltip.getOrCreateInstance(promoCodeBlock)
                } catch (error) {
                }

                promoCodeBlock.addEventListener('click', () => {
                    let element = promoCodeBlock.querySelector('.promo-code');

                    if (element == null) {
                        return;
                    }

                    // Create a fake `textarea` and set the contents to the text
                    // you want to copy
                    const storage = document.createElement('textarea');

                    storage.value = element.innerText;
                    element.appendChild(storage);

                    // Copy the text in the fake `textarea` and remove the `textarea`
                    storage.select();
                    storage.setSelectionRange(0, 99999);

                    document.execCommand('copy');
                    element.removeChild(storage);

                    // Only for hyperhost event promo
                    // It no working on hyperhost.ua/info, so don`t copy it to it
                    if (!promoCodeBlock.hasAttribute('aria-describedby')) {
                        try {
                            copyTooltip.show()

                            setTimeout(() => {
                                copyTooltip.hide()
                            }, 2000)
                        } catch (error) {
                        }
                    }
                })
            }

            if (document.querySelector('.event-promo-section') != undefined) {
                timerEvent()
                promoBTNSEvent()
            }
        }
    })
})
