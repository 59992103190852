function Rating(ratingBlock) {

    this.$ratingBlock = ratingBlock

    this.$inputs = this.$ratingBlock.find('.star-input');

    this.$stars = this.$inputs.next();

    this.$options = this._mergeObjects({}, rating.defaults)

    this._init()
}

Rating.prototype = {
    _init() {
        this._fillUntil(this.$ratingBlock.data('ratings') / 2)

        let value = this.$ratingBlock.data('ratings')

        this.$stars.on('click', (e) => {
            if (this.$ratingBlock.attr('data-read-only') != 'true') {
                let star = $(e.target)
                value = this._convertStarValue(star.attr('for'))

                this._fillUntil(value)

                this.$ratingBlock.data('ratings', value * 2)
            }
        })

        this.$ratingBlock.data('ratings', value)
    },

    _fillUntil(index) {
        let i = this._roundUp(index);

        this.$stars.each((index, star) => {
            let forInput = this._convertStarValue($(star).attr('for'))

            if (parseFloat(forInput) > parseFloat(i)) {
                if ($(star).attr('for').replace('star', '').length > 1) {
                    $(star)
                        .removeClass(this.$options.filledHalf)
                        .removeClass(this.$options.filledFull)
                        .addClass(this.$options.empty)
                }
            } else if (parseFloat(forInput) == parseFloat(i)) {
                if ($(star).attr('for').replace('star', '').length > 1) {
                    $(star)
                        .removeClass(this.$options.empty)
                        .removeClass(this.$options.filledFull)
                        .addClass(this.$options.filledHalf)
                }
            } else {
                if ($(star).attr('for').replace('star', '').length > 1) {
                    $(star)
                        .removeClass(this.$options.filledHalf)
                        .removeClass(this.$options.empty)
                        .addClass(this.$options.filledFull)
                }
            }
        })

    },

    _convertStarValue(value) {
        value = value.replace('star', '').slice(0, 1) + '.' + value.replace('star', '').slice(1)

        if (value.length <= 2) {
            value = value + '0'
            if (value == '0.0') {
                value = '0.5'
            }
        }

        if (value == '0..5') {
            value = '0.5'
        }


        return value
    },

    _roundUp(num, precision = 1) {
        let factor = Math.pow(2, precision);

        return Math.ceil(num * factor) / factor;
    },

    _mergeObjects(target, ...sources) {
        Object.assign(target, ...sources);
        return target;
    },
}

window.rating = function (ratingBlock) {
    return new Rating(ratingBlock);
}

// Plugin defaults.
window.rating.defaults = {
    empty: 'star empty',
    filledHalf: 'star half',
    filledFull: 'star full',
};

module.exports = Rating;
