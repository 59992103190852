require('./includes/modal-dialog')
require('./includes/custom-select')
require('./coockies')
require('./event-promo')

import Stars from './modules/Stars'; //rating stars module
Stars(pageBaseURL)

$(window).on('load', () => {
    // Scroll btn function start

    let scrollBtn = $('#scroll-to-top-btn');
    scrollBtn.fadeOut()

    let progressSVG = $('.progress-wrap svg');
    let progressCircle = progressSVG.find('.progress-circle');

    progressCircle.css({
        'stroke-dasharray': '156px',
        'stroke-dashoffset': '156px',
    });

    let getPercentage = (scrollPosition, maxScroll, maxClientScroll) => {
        return parseInt((scrollPosition / (maxScroll - maxClientScroll)) * 100);
    }

    let changeScrollLine = (percentage) => {
        percentage = 156 - (156 * (percentage / 100));

        progressCircle.css('stroke-dashoffset', percentage)
    }

    $(window).on('scroll', (e) => {
        const clientHeight = document.documentElement.clientHeight;
        const scrollHeight = document.documentElement.scrollHeight;
        const scrollTop = document.documentElement.scrollTop;

        let percentage = getPercentage(scrollTop, scrollHeight, clientHeight);
        changeScrollLine(percentage)

        if (percentage >= 5) {
            if (parseInt(scrollBtn.css('opacity')) <= 1) {
                scrollBtn.fadeIn()
            }

            return;
        }

        scrollBtn.fadeOut()
        return;
    });

    scrollBtn.on('click', (e) => {
        window.scrollTo({ top: 0 })
    })
})
