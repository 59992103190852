document.addEventListener('DOMContentLoaded', function () {

    function addScrollElement(element) {
        let beforeScroll = document.createElement('span');
        beforeScroll.classList.add('before-scroll');

        let afterScroll = document.createElement('span');
        afterScroll.classList.add('after-scroll');

        element.appendChild(beforeScroll);
        element.appendChild(afterScroll);

        enableTouchScroll(element);
        enableThumbDragging(afterScroll, element);
        attachScrollEvents(element);
        enableAutoScrollOnSelect(element);
    }

    document.querySelectorAll('.with-scroll').forEach(function (element) {
        addScrollElement(element);
    });

    function observeNewElements() {
        const observer = new MutationObserver((mutationsList) => {
            mutationsList.forEach((mutation) => {
                mutation.addedNodes.forEach((node) => {
                    if (node.nodeType === Node.ELEMENT_NODE && node.classList.contains('with-scroll')) {
                        addScrollElement(node);
                    } else if (node.nodeType === Node.ELEMENT_NODE) {
                        node.querySelectorAll('.with-scroll').forEach((element) => {
                            addScrollElement(element);
                        });
                    }
                });
            });
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    }

    observeNewElements();

    function updateScrollBar(element) {
        let elementToScroll = element.querySelector('.scrollable') || element;

        let visibleHeight = elementToScroll.clientHeight;
        let contentHeight = elementToScroll.scrollHeight;
        let beforeScroll = element.querySelector('.before-scroll');
        let afterScroll = element.querySelector('.after-scroll');

        if (visibleHeight + 32 >= contentHeight) {
            beforeScroll.style.height = '0px';
            afterScroll.style.height = '0px';
            return;
        }

        let scrollTop = elementToScroll.scrollTop;
        let scrollThumbHeight = (visibleHeight / contentHeight) * visibleHeight;
        let beforeHeight = visibleHeight - 32;
        let thumbPosition = (scrollTop / contentHeight) * visibleHeight;

        thumbPosition = Math.max(16, thumbPosition);
        scrollThumbHeight = Math.max(scrollThumbHeight, 16);

        if (thumbPosition + scrollThumbHeight >= beforeHeight + 16) {
            thumbPosition = (beforeHeight + 16) - scrollThumbHeight;
        }

        beforeScroll.style.height = beforeHeight + 'px';
        afterScroll.style.height = scrollThumbHeight + 'px';
        afterScroll.style.top = thumbPosition + 'px';
    }

    function enableThumbDragging(thumb, scrollContainer) {
        let isDragging = false;
        let startY = 0;
        let startScrollTop = 0;

        thumb.addEventListener('pointerdown', function (e) {
            isDragging = true;
            startY = e.clientY;
            let textarea = scrollContainer.querySelector('.scrollable') || scrollContainer;
            startScrollTop = textarea.scrollTop;

            document.body.style.userSelect = 'none';
        });

        document.addEventListener('pointermove', function (e) {
            if (!isDragging) return;

            let deltaY = e.clientY - startY;
            let textarea = scrollContainer.querySelector('.scrollable') || scrollContainer;
            let visibleHeight = textarea.clientHeight;
            let contentHeight = textarea.scrollHeight;

            let scrollAmount = (deltaY / visibleHeight) * contentHeight;
            textarea.scrollTop = startScrollTop + scrollAmount;

            updateScrollBar(scrollContainer);
        });

        document.addEventListener('pointerup', function () {
            if (isDragging) {
                isDragging = false;
                document.body.style.userSelect = '';
            }
        });
    }

    function enableTouchScroll(scrollContainer) {
        let startY = 0;
        let startScrollTop = 0;
        let isTouching = false;

        scrollContainer.addEventListener('touchstart', function (e) {
            isTouching = true;
            startY = e.touches[0].clientY;
            let elementToScroll = scrollContainer.querySelector('.scrollable') || scrollContainer;
            startScrollTop = elementToScroll.scrollTop;
        });

        scrollContainer.addEventListener('touchmove', function (e) {
            if (!isTouching) return;

            let deltaY = e.touches[0].clientY - startY;
            let elementToScroll = scrollContainer.querySelector('.scrollable') || scrollContainer;
            let visibleHeight = elementToScroll.clientHeight;
            let contentHeight = elementToScroll.scrollHeight;

            let scrollAmount = deltaY + (deltaY * Math.log10(contentHeight / visibleHeight));
            elementToScroll.scrollTop = startScrollTop - scrollAmount;

            updateScrollBar(scrollContainer);
            e.preventDefault();
        });

        scrollContainer.addEventListener('touchend', function () {
            if (isTouching) {
                isTouching = false;
            }
        });
    }

    function handleMouseWheelEvent(e, element) {
        let elementToScroll = element;

        let visibleHeight = elementToScroll.clientHeight;
        let contentHeight = elementToScroll.scrollHeight;

        if (visibleHeight < contentHeight) {
            e.preventDefault();
            smoothScroll(elementToScroll, e.deltaY);
        }
    }

    function smoothScroll(element, delta) {
        $(element).stop().animate({
            scrollTop: element.scrollTop + delta * 2
        }, 300, 'swing');
    }

    function attachScrollEvents(scrollContainer) {
        let scrollableElement = scrollContainer.querySelector('.scrollable') || scrollContainer;

        scrollableElement.addEventListener('scroll', () => updateScrollBar(scrollContainer));

        scrollableElement.addEventListener('wheel', function (e) {
            handleMouseWheelEvent(e, scrollableElement);
        });
    }

    if ('ResizeObserver' in window) {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                if (entry.target.classList.contains('with-scroll')) {
                    updateScrollBar(entry.target);
                }
            }
        });

        document.querySelectorAll('.with-scroll').forEach(function (element) {
            resizeObserver.observe(element);
        });
    }

    window.addEventListener('resize', () => {
        document.querySelectorAll('.with-scroll').forEach(function (element) {
            updateScrollBar(element);
        });
    });

    function enableAutoScrollOnSelect(element) {
        const scrollable = element.querySelector('.scrollable') || element;
        let isDragging = false;
        let autoScrollInterval = null;

        document.addEventListener('pointermove', function (e) {
            if (!window.getSelection().toString() || !isDragging) {
                clearInterval(autoScrollInterval);
                return;
            }

            const rect = scrollable.getBoundingClientRect();
            const scrollThreshold = 30;
            const scrollSpeed = 20;

            if (e.clientY - rect.top < scrollThreshold) {

                clearInterval(autoScrollInterval);
                autoScrollInterval = setInterval(() => {
                    scrollable.scrollTop -= scrollSpeed;
                    updateScrollBar(element);
                }, 20);
            } else if (rect.bottom - e.clientY < scrollThreshold) {

                clearInterval(autoScrollInterval);
                autoScrollInterval = setInterval(() => {
                    scrollable.scrollTop += scrollSpeed;
                    updateScrollBar(element);
                }, 20);
            } else {
                clearInterval(autoScrollInterval);
            }
        });

        scrollable.addEventListener('pointerdown', function () {
            isDragging = true;
            clearInterval(autoScrollInterval);
        });

        document.addEventListener('pointerup', function () {
            if (isDragging) {
                isDragging = false;
                document.body.style.userSelect = '';
            }
        });

        // Prevent scrolling of the entie page when auto-scrolling is active
        function preventScroll(e) {

            if (isDragging) {
                $('body').css('overflow', 'hidden')
                e.preventDefault()
            } else (
                $('body').css('overflow', 'initial')
            )
        }

        document.addEventListener('scroll', preventScroll, { passive: false });
        document.addEventListener('wheel', preventScroll, { passive: false });

    }


});
